



















































































































































































































































































































































































































































































































































































































































.no_border_upload {
  .el-upload.el-upload--text {
    border: 0;
    height: auto !important;
    margin: 0 8px;
  }
}
